// ------------- GENERAL TAGS ------------- //


html {
  font-size: 18px;
}

body {
    height: 100%;
    width: 100%;
    font-size: 1.125rem;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.44;
    letter-spacing: normal;
    font-family: $font-regular;
    color: $warm-grey;
  
    @media (max-width:$medium-media-query-size) {
      font-size: 1rem;
    }

}

a {
    text-decoration: none;
    @include transition(.2s);

    &:hover {
      text-decoration: none;
      color: $dark-lavender;

    }
}


h1 {
  font-size: 1.75rem;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  font-family: $font-heading-regular;
  color: $charcoal-grey;
  margin-bottom: 1.25rem;
  line-height: 1.5;
  letter-spacing: 0.15rem;
  text-transform: uppercase;
  
  @media (max-width:$medium-media-query-size) {
    font-size: 1.5rem;
  }
  @media (max-width:$small-media-query-size) {
    font-size: 1.25rem;
  }

}


h2 {
  font-size: 1.25rem;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.5;
  letter-spacing: normal;
  font-family: $font-heading-bold;
  color: $charcoal-grey;
  margin-bottom: 1.25rem;
  text-transform: uppercase;
  letter-spacing: 0.15rem;

  @media (max-width:$medium-media-query-size) {
    font-size: 1rem;
  }


}

h3 {
  font-size: 1.375rem;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  font-family: $font-heading-bold;
  color: $charcoal-grey;
  margin-bottom: 1.125rem;
  text-transform: uppercase;
  
  @media (max-width:$medium-media-query-size) {
    font-size: .95rem;
  }
  @media (max-width:$small-media-query-size) {
    font-size: .85rem;
  }


}



p {
  font-size: 1rem;
  font-style: normal;
  font-stretch: normal;
  line-height: 2;
  letter-spacing: normal;
  font-family: $font-regular;
  color: $charcoal-grey;
  margin-bottom: 1.125rem;
  letter-spacing: 0.1rem;

  @media (max-width:$medium-media-query-size) {
    font-size: 0.85rem;
    line-height: 1.5rem;

  }
  @media (max-width:$small-media-query-size) {
    font-size: 0.75rem;
    line-height: 1.3rem;
  }

  
}

ul {
  font-size: 1.125rem;
  font-style: normal;
  font-stretch: normal;
  line-height: 2;
  letter-spacing: normal;
  font-family: $font-regular;
  color: $warm-grey;
  margin-bottom: calc(1.125rem / 2);

  @media (max-width:$medium-media-query-size) {
    font-size: 0.85rem;
    line-height: 1.5rem;

  }
  @media (max-width:$small-media-query-size) {
    font-size: 0.75rem;
    line-height: 1.3rem;
  }

  margin-bottom: 1rem;

  li {
    + li {
      margin-top: .5rem;
    }
  }
}
