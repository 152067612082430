.work-list {
    position: relative;

    &-inner {
        padding: 3rem;
        
        @media (max-width:$medium-media-query-size) {
            padding: 1.5rem;
        }
    
        @media (max-width:$small-media-query-size) {
            padding: 1rem;
        }


        h1 {
            text-align: center;
        }
    }
}

.work-list-items {
    display: flex;
    flex-wrap: wrap;

    > li {
        width: 25%;
        padding: 0 1rem;
        margin: 0 0 2rem 0;

        @media (max-width:$medium-media-query-size) {
            width: 50%;
            padding: 0 1rem;
            margin: 0 0 2rem 0;
        }
    
        @media (max-width:$small-media-query-size) {
            width: 100%;
            padding: 0 1rem;
            margin: 0 0 2rem 0;
        }


        a {
            display: block;
            width: 100%;
            padding-top: 100%; /* 1:1 Aspect Ratio */
            position: relative; /* If you want text inside of it */
            transform: scale(1);
        font-family: $font-heading-regular;

            > div {
                    position: absolute;
                    top: 0;
                    left: 0;
                    bottom: 0;
                    right: 0;
                    background-position: center;
                    background-size: cover;
            }

            &:hover {
                transform: scale(1.05);
                .work-list-items-hover {
                    opacity: 1;
                }
            }

            .work-list-items-hover {
                position: absolute;
                width: 100%;
                height: 100%;
                top: 0;
                left: 0;
                display: flex;
                justify-content: center;
                align-items: center;
                background: rgba(255,255,255,0.75);
                flex-direction: column;
                opacity: 0;
                @include transition(.4s);

                @media (max-width:$medium-media-query-size) {
                    height: 60px;
                    bottom: 0;
                    opacity: 1;
                    top: unset;
                }

                span {
                    &.project-name {
                        text-transform: uppercase;
                        font-size: 0.85rem;
                        letter-spacing: .15rem;
                        color: $warm-grey;
                        text-align: center;
                        font-family: $font-heading-regular;

                    }
                }
            }
        }
    }
}