header {
  z-index: 999;
  width: calc(100% - 1rem);
  background: transparent;
  padding: 1rem 2rem;
  background: #fff;
  border-bottom: 2px solid lighten($light-grey, 2%);
  @include transition(.4s);
  position: fixed;
  top: 0.5rem !important;

  &.sticky {
    position: fixed;
    top: 0 !important;

  }

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 auto;
  
    @media (max-width:$medium-media-query-size) {
      width: 100%;
      flex-wrap: wrap;
      padding: 0 !important;
    }
  }

  @media (max-width:$medium-media-query-size) {
    padding: 5px 20px;
  }

}


/* LOGO STYLING */
.header-logo {
  height: 60px;
  width: 150px;
  position: relative;

  @media (max-width:$medium-media-query-size) {
    margin: 10px;
    order: 1;
    flex-grow: 1;
  
  }
  a {
    display: block;
    height: 100%;
    img {
      height: 100%;
    }
    &:hover svg {
          transform: scale(1.1);
    }
    @media (max-width:$medium-media-query-size) {
    
    }
  
  }

  svg {
    height: 100%;
    width: 100%;
    transform: scale(1);
    @include transition(.4s);
  }
}

/* MOBILE TRIGGER */
.header-mobile-trigger {
  display: none;

  @media (max-width:$medium-media-query-size) {
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    height: 20px;
    width: 20px;
    @include transition(.4s);
    order: 3;

    svg {
      fill: $charcoal-grey;
      height: 100%;
      width: 100%;
      @include transition(.4s);
    }

    span {
      font-size: .9rem;
      margin-right: 10px;
    }

    &.is-active {
      svg {
        fill: $dark-lavender;

      }
    }

  }

}
.btn-booking {
  @media (max-width:$medium-media-query-size) {
    order: 2;
    margin-right: 20px;
  }

  @media (max-width:$small-media-query-size) {
    display: none;
  }
}
/* HEADER NAVIGATION */
.header-nav {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  @include transition(.4s);

  @media (max-width:$medium-media-query-size) {
    width: 100%;
    flex-direction: column;
    justify-content: center;
    margin: 0;
    max-height: 0;
    overflow: hidden;
    order: 4;
    background: #fff;
    &.is-active {
      max-height: 1000px;
    }
  }

  a {
    font-size: 0.875rem;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.44;
    letter-spacing: normal;
    font-family: $font-heading-regular;
    display: inline-block;
    color: rgba($charcoal-grey, 0.7);
    text-transform: uppercase;
    position: relative;
    @include transition(.4s);


    @media (max-width:$medium-media-query-size) {
      flex-grow: 1;
      width: 100%;
      padding: 20px;
      text-align: center;
      border-top: 1px solid darken($gray-light, 5%);
      position: relative;
      z-index: 10;
      display: flex;
      justify-content: center;
      align-items: center;

    }

    @media (min-width:$medium-media-query-size) {
      margin-top: 2px;
      
      + a {
        margin-left: 40px;
      }

      &.active, &:hover {
        text-decoration: none;
        color: $charcoal-grey;

     }
    }


  }

}
