.work-single-info {
    position: relative;

    h1 {
        text-align: left;
        text-transform: uppercase;
        letter-spacing: .25rem;
        font-family: $font-heading-regular;
        font-size: 1.5rem;
        color: $warm-grey;

    }

    &-inner {
        padding: 3rem 3rem 0 3rem;
        max-width: $max-width;
        margin: 0 auto;
        display: flex;
        @media (max-width:$medium-media-query-size) {
            padding: 1.5rem 1.5rem 0 1.5rem;
        }

        @media (max-width:$small-media-query-size) {
            padding: 1rem 1rem 0 1rem;
        }

        h1 {
        }
    }

    &-center {
        flex-grow: 1;
    }

    &-details {
        padding: 1rem;
        background: #fff;

        &-item {

            .label {
            }

            .value {
                
            }
        }
    }
}

.work-single-slider {
    position: relative;
}
// slider
.slick-slide {
    opacity: 0.4;
    max-height: 750px;
    transform: scale(1);
    padding: 10px;

    @media (max-width:$medium-media-query-size) {
        max-height: 450px;
    }

    @media (max-height: 1024px)  {
        max-height: 550px;
    }


    @include transition(.4s);

    &.slick-current {
        opacity: 1;
    }
}

.slick-arrow {
    position: absolute;
    top: 0;
    background: none;
    outline: none !important;
    border: none !important;
    height: 100%;
    width: 150px;
    z-index: 80;
    cursor: pointer;


    @media (max-width:$small-media-query-size) {
        width: 50px;
    }


    &.slick-prev {
        left: 0;
    }

    &.slick-next {
        right: 0;
    }

    svg {
        width: 30px;
        height: 30px;
        fill: #fff;
        opacity: 1;
    }
}

.work-single-info-details {
    padding: 0 calc(3rem - 10px) 1rem calc(3rem - 10px);
    max-width: $max-width;
    margin: 0 auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    &-item {
        margin: 10px;
        font-size: .65rem;
        font-family: $font-heading-regular;
        text-transform: uppercase;
        letter-spacing: 0.15rem;

        .label {
            text-transform: uppercase;

        }
    }
}
#work-single-info {
    position: absolute;
    width: 1px;
    height: 1px;
    bottom: 150px;
    left: 0;
    right: 0;
    margin: auto;

}
.work-single-more-projects {
    position: absolute;
    width: 30px;
    height: 30px;
    bottom: 10px;
    left: 0;
    right: 0;
    margin: auto;

    svg {
        width: 30px;
        height: 30px;
        fill: #fff;
        opacity: 1;
    }

    &-hover {
        font-size: .85rem;
        font-family: $font-heading-regular;
        text-transform: uppercase;
        letter-spacing: 0.15rem;
        text-align: center;
        position: absolute;
        top: -20px;
        left: -40px;
        right: 0;
        margin: auto;
        opacity: 0;
        transition: all 0.4s ease-in;
        width: 120px;
        color: #fff;
        @include transition(.4s);

    }

    &:hover {
        .work-single-more-projects-hover {
            opacity: 1;
        }
    }
}


.slick-slider,
.slick-carousal,
.slider-nav
{
    visibility: hidden; 
    opacity:0; 
    -ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=0)'; 
    -webkit-transition: 
    opacity 0.5s linear; 
    transition:opacity 0.5s linear; 
}

.slick-initialized { 
    visibility: visible !important; 
    opacity:1 !important; 
    -ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=100)'; 
}

