@font-face {
    font-family: 'avantgarde_mediummedium';
    src: url('../fonts/avant-medium-webfont.woff2') format('woff2'),
         url('../fonts/avant-medium-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'avantgarde_normalbook';
    src: url('../fonts/avant-normal-webfont.woff2') format('woff2'),
         url('../fonts/avant-normal-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}