


.btn-primary, .btn {
  font-size: 0.875rem;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.44;
  letter-spacing: normal;
  font-family: $font-heading-bold;
  color: #fff;
  display: inline-block;
  text-transform: uppercase;
  padding: 0.5rem 2.5rem;
  transform: scale(1);
  overflow: hidden;
  border: 2px solid transparent;

  @media (max-width:$medium-media-query-size) {
    padding: .5rem 1rem;
  }
  
  &:hover {
    transform: scale(1.05);
    background: darken($dark-lavender, 5%);
    color: $dark-lavender;
    border-color: $dark-lavender;

    &:after {
      left: -10%;
      width: 120%;
    }
  }

  &:after {
    position: absolute;
    transition: .3s;
    content: '';
    width: 0;
    left: 50%;
    bottom: 0;
    height: 3px;
    background: #ffffff;
    height: 120%;
    left: -10%;
    transform: skewX(15deg);
    z-index: -1;
    @include transition(.2s);

  }
}

.btn-main-dark {
  text-transform: uppercase;
  padding: 0.75rem;
  color: $warm-grey;
  border: 1px solid $warm-grey;
  margin-top: 1rem;
  font-style: normal;
  font-stretch: normal;
  font-family: $font-heading-regular;
  font-size: 0.65rem;
  line-height: 1;
  width: 180px;
  text-align: center;
  letter-spacing: 0.15rem;
  &:hover {
    background: $warm-grey;
    border-color: $warm-grey;
    color: #fff;
    opacity: 1;
  }
}