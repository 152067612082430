.fifty-block {
    position: relative;

    &-inner {
        padding: 3rem;
        max-width: $max-width;
        margin: 0 auto;
        display: flex;

        @media (max-width:$medium-media-query-size) {
            padding: 1.5rem;
        }
    
        @media (max-width:$small-media-query-size) {
            padding: 1rem;
        }


        > * {
            width: calc(50% - 1.5rem);
            flex-grow: 1;

            + * {
                margin-left: 3rem;
            }
        }

    }

    &-img {
        background-position: center;
        background-size: cover;
        width: 100%;
        height: 100%;
        }
}


.full-block {
    background-position: center;
    background-size: cover;
    position: relative;
    &-inner {
        max-width: $max-width;
        margin: 0 auto;
        padding: 3rem;
        position: relative;
        z-index: 10;

        @media (max-width:$medium-media-query-size) {
            padding: 3rem 1.5rem;
        }
    
        @media (max-width:$small-media-query-size) {
            padding: 1rem;
        }


        &-cta {
            margin-top: 3rem;

        }


    
    }
    &:after {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: $gray-light;
        opacity: 0.85;
        content: '';
        z-index: 5;
    }
    
}

.full-image {
    margin: 1.5rem 0;

    > img {
        width: 100%;
    }

    @media (max-width:$medium-media-query-size) {
        margin: 1rem 0;
    }

}