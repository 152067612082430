.press {
    position: relative;

    &-inner {
        padding: 3rem;
        max-width: 800px;
        margin: 0 auto;

        @media (max-width:$medium-media-query-size) {
            padding: 1.5rem;
        }
    
        @media (max-width:$small-media-query-size) {
            padding: 1rem;
        }
    }
}

.press-inner-item {
    padding-bottom: .125rem;
    border-bottom: 1px solid rgba($silver, 0.5);
    margin-bottom: 1rem;

    &-description {
        font-size: .55rem;
        font-family: $font-heading-regular;
        text-transform: uppercase;
        letter-spacing: 0.15rem;
        margin-top: .25rem;
        line-height: 1;

    }
}