.hero {
    position: relative;

    &-inner {
        margin: 0 auto;
        display: flex;
        position: relative;
        align-items: center;
        padding: 0;

        @media (max-width:$medium-media-query-size) {
            padding: 0;
            max-width: 100%;
            flex-direction: column;
        }

    }

    &-heading {
        width: 40%;
        z-index: 50;
        
        &-inner {
            width: calc(100% + 300px);
            padding: 3rem;
            position: relative;
            margin-left: -2rem;
            display: flex;
            justify-content: flex-end;

            @media (max-width:$medium-media-query-size) {
                width: 100%;
                margin: 0;
                padding: 2rem;
                justify-content: center;

                h1 {
                    text-align: center;
                }
            }

            &:after {
                content: '';
                background: lighten($light-grey, 2%);
                position: absolute;
                top: 0;
                height: 100%;
                width: 100vw;
                right: 0;
                z-index: -1;
                opacity: 0.85;
                @media (max-width:$medium-media-query-size) {
                    background: #fff;
                }
            }
        }

        h1 {
            margin-bottom: 0;
            text-transform: uppercase;
            max-width: 600px;
        }

        @media (max-width:$medium-media-query-size) {
            order: 2;
            width: 100%;
            max-width: 100% !important;
            padding: 0;
        }
    }



    &-image-full-height {
        width: 100%;
        position: relative;
        max-height: calc(100vh - 217px);
        overflow: hidden;
        @media (max-width:$small-media-query-size) {
            max-height: 400px
        }

        &-inner {
            width: 100;
            display: flex;
            justify-content: center;
            align-items: center;
            .slide-item-bg {
                width: 100%;
                height: calc(100vh - 217px);
                background-position: center;
                background-size: cover;
                @media (max-width:$small-media-query-size) {
                    height: 300px;
                }

                @media (max-width:$medium-media-query-size) {
                    height: 450px;
                }

        
            }

            @media (max-width:$medium-media-query-size) {
                width: 100%;
                margin: 0;
            }
        }

        @media (max-width:$medium-media-query-size) {
            order: 1;
            width: 100%;
        }
    }

    &-image {
        width: 60%;
        position: relative;

        &-inner {
            width: calc(100% + 200px);
            margin-left: -200px;
            height: calc(100vh - 210px);
            
            @media (max-width:$small-media-query-size) {
                height: 400px
            }

            li {
                height: calc(100vh - 210px);
                @media (max-width:$small-media-query-size) {
                    height: 400px
                }

            }

            .hero-img-slide {
                height: calc(100vh - 234px - 2rem);
                background-size: cover;
                background-position: center;

                @media (max-width:$small-media-query-size) {
                    height: 300px;
                }
        
            }
            @media (max-width:$medium-media-query-size) {
                width: 100%;
                margin: 0;
            }
        }

        @media (max-width:$medium-media-query-size) {
            order: 1;
            width: 100%;
        }



    }
    }




.border-wrapper {
    @media (max-width:$small-media-query-size) {
        display: flex;
        flex-direction: column;

        .hero-desktop {
            flex-grow: 1;

            .hero-image-full-height {
                max-height: 100%;
            }
        }
    }

}

.hero-mobile {
    display: none;
}


.is-android {
    @media (max-width:$small-media-query-size) {

        .slide-item-bg {
            height: 250px;
        }

    }
}