// ------------- MEDIA QUERIES ------------- //

//@media (max-width:$x-large-media-query-size)
$x-large-media-query-size: 1600px;

//@media (max-width:$large-media-query-size)
$large-media-query-size: 1280px;

//@media (max-width:$medium-media-query-size)
$medium-media-query-size: 1025px;

//@media (max-width:$small-media-query-size)
$small-media-query-size: 640px;

//@media (max-width:$mobile-media-query-size)
$mobile-media-query-size: 340px;


// ------------- BREAKPOINTS MAP ------------- //

$breakpoints: (
    large : $large-media-query-size,
    medium: $medium-media-query-size,
    small : $small-media-query-size,
    mobile : $mobile-media-query-size
);


// ------------- COLORS ------------- //

$primary-color:				#231f20;
$green-dark:          #006054;
$green-light:         #96cd00;
$teal:                #26c7be;
$teal-light:          #b8e5e1;
$orange:              #ff4400;

$red:                 #c41230;
$gray:                #404040;
$grayLight:           #d4d7dd;
$graySuperLight:      #f7f7f7;
$grayMedium:          #979797;
$yellow:              #fdc312;

$beige:               #f1f3e3;
$green:               #98c940;
$gray-dark:           #1e1e1e;
$gray-light:          #f8f8f8;
$gray-medium:         #f5f5f5;
$blue:                #528CBC;


$light-grey-two: #f1f2f2;
$warm-grey: #808080;
$dark-lavender: #786890;
$heather: #c1bacc;

$charcoal-grey: #2a2a2a;
$silver: #666666;
$light-grey: #f2f2f2;

// ------------- FONTS ------------- //

$font-regular: 'Open Sans', sans-serif;
$font-heading-regular: 'avantgarde_normalbook', sans-serif;
$font-heading-bold: 'avantgarde_mediummedium', sans-serif;

$font-regular-weight: normal;
$font-light-weight: 300;
$font-medium-weight: 500;
$font-thin-weight: 100;

// ------------- FONT WEIGHTS ------------- //

$normal:    400;
$bold:      700;


// ------------- WHITESPACE/WIDTH ------------- //

$column-gutter:				30px;
$max-width:           1170px;


// ------------- Z-INDEX MAP ------------- //

$zindex: (
  z-index-0     : -1,
  z-index-1     : 10,
  z-index-2		: 20,
  z-index-3		: 30,
  z-index-4		: 40,
  z-index-5		: 50,
  z-index-6		: 60,
  z-index-7		: 70,
  z-index-8		: 80,
  z-index-9		: 90
);

/* z-index: map-get($zindex, z-index-#); */
