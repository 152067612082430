.contact {
    position: relative;

    &-inner {
        padding: 3rem;
        max-width: $max-width;
        margin: 0 auto;

        @media (max-width:$medium-media-query-size) {
            padding: 1.5rem;
        }
    
        @media (max-width:$small-media-query-size) {
            padding: 1rem;
        }


    }
}

.contact-inner-item {
    display: flex;

    @media (max-width:$medium-media-query-size) {
        flex-direction: column;
    }

    + .contact-inner-item {
        margin-top: 6rem;
        
        @media (max-width:$medium-media-query-size) {
            margin-top: 3rem;
        }
    
        @media (max-width:$small-media-query-size) {
            margin-top: 1.5rem;
        }

    }

    &-content {
        @media (min-width:$medium-media-query-size) {
            width: 35%;
            margin-right: 2rem;

        }
        flex-grow: 1;
        padding: 1rem 0;
    }

    &-map {
        flex-grow: 1;
        @media (min-width:$medium-media-query-size) {
            width: 65%;

        }
    }
}

.contact-inner-item-content-info {

    &-label {
        font-size: .65rem;
        font-family: $font-heading-regular;
        text-transform: uppercase;
        letter-spacing: 0.15rem;
    }

    &-value {
        font-size: 0.9rem;
        font-family: $font-regular;
        color: $charcoal-grey;
        letter-spacing: 0.1rem;
        line-height: 2;
    }

    + .contact-inner-item-content-info {
        margin-top: 1rem;
    }
}


.contact-inner-item-map {
    display: flex;
    height: 450px;
    @media (max-width:$medium-media-query-size) {
        height: 200px;
    }

    &.contact-inner-item-map-form {
        height: 100%;
    }

    .contact-inner-item-map-embed {
        display: none;
        width: 100%;
        height: 100%;
        @media (max-width:$medium-media-query-size) {
            height: 200px;
        }

        iframe {
            width: 100%;
            height: 100%;
    
        }
    }

    .contact-inner-item-map-hover {

    }

    &.active {
        .contact-inner-item-map-embed {
            display: block;
        }
    
        .contact-inner-item-map-hover {
            display: none;
        }
    
    }

    &-hover {
        flex-grow: 1;
        width: 100%;
        height: 100%;
        display: flex;
        background-position: center;
        background-repeat: no-repeat;
        cursor: pointer;
        height: 450px;
        background-size: cover;

        @media (max-width:$medium-media-query-size) {
            height: 200px;
            height: 100%;
        }
        &-msg {
            font-size: .65rem;
            font-family: $font-heading-regular;
            text-transform: uppercase;
            letter-spacing: 0.15rem;
            color: #fff;
            display: flex;
            justify-content: center;
            align-items: center;
            background-size: cover;
            width: 100%;
            height: 100%;
            background: rgba($charcoal-grey, 0.8);
            opacity: 0;
            @include transition(.2s);
            @media (max-width:$medium-media-query-size) {
                min-height: 200px;
                height: 200px;
            }
    
        }

        &:hover {
            > * {
                opacity: 1;
            }
        }
    }
}

.field-item-wrapper {
    .label {
        font-size: .65rem;
        font-family: $font-heading-regular;
        text-transform: uppercase;
        letter-spacing: 0.15rem;
        margin-bottom: 0.5rem;
    }

    .field {
        input, textarea {
            padding: 10px;
            font-size: 0.9rem;
            line-height: 2;
            font-family: $font-regular;
            color: $charcoal-grey;
            letter-spacing: 0.1rem;
            border: 1px solid rgba($charcoal-grey, 0.25);
            @include transition(.2s);
            width: 100%;

            &:hover, &:focus {
                border: 1px solid rgba($charcoal-grey, 0.5);

            }
        }
        
    }

    + .field-item-wrapper {
        margin-top: 1rem;
    }
}


.contact-inner-item-form {
    form {
        width: 100%;
    }

    .contact-inner-item-map-form {
        margin-top: 2rem;
    }

    .submit-item-wrapper {
        margin-top: 1rem;

        input {
            padding: 1rem;
            color: $silver;
            border: 1px solid $silver;
    
            text-transform: uppercase;
    
        }
    }
}