.max-width {
  width: $max-width;
  margin: 0 auto;

  @media (max-width:$large-media-query-size) {
    width: 100%;
    padding-left: 20px;
    padding-right: 20px;
  }
}

.border-wrapper {
  margin: 0.5rem;
  overflow: hidden;
  position: relative;
  box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.15);
  background: #fff;

  padding-top: 98px;

  @media (max-width:$medium-media-query-size) {
    padding-top: 92px;
  }
}

body {
  overflow-x: hidden;
  background: $light-grey;
  height: calc(100% - 4rem);
  
  @media (max-width:$small-media-query-size) {
    height: auto;
  }
}


.page-pattern {
  margin-top: -110px; //offset for header
  padding: 170px 0 60px;
  @media (max-width:$medium-media-query-size) {
    padding: 130px 0 60px;

  }
  &-inner {
      max-width: $max-width;
      margin: 0 auto;
        @media (max-width:$medium-media-query-size) {
        padding-left: 20px;
        padding-right: 20px;
    }

  }
}