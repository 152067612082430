footer {
  overflow: hidden;
  padding: 1rem 2rem;
  background: #fff;
  border-top: 2px solid lighten($light-grey, 2%);

  @media (max-width:$medium-media-query-size) {
    padding: 1rem;
  }

}



.footer-main {
  margin: 0 auto;
  position: relative;
  

  @media (max-width:$medium-media-query-size) {
    max-width: 100%;
  }

  * {
    color: $charcoal-grey;
    font-size: 0.875rem;
    margin-bottom: 0;
  }

  &-inner {
    margin: 0 auto;
    display: flex;
    align-items: center;

    @media (max-width:$medium-media-query-size) {
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }


    &-col {

      &-social {
        flex-grow: 1;
      }

      @media (max-width:$medium-media-query-size) {
        width:100%;
        padding: 10px;

        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;

        h4 {
          text-align: center;

        }
      }

      + * {
        margin-left: 1rem;

        @media (max-width:$medium-media-query-size) {
            border-left: none;
            margin-left: 0;
            margin-top: 0;
        }
      }
    }

  }

}

.footer-menu-social {
  display: flex;
  margin-bottom: 0;

  li {
    margin-right: 1rem;
    margin-top: 0;

    a {
      display: block;
      width:1rem;
      height: 1rem;
      border-radius: 1rem;
      transform: scale(1);

      &:hover  {
        transform: scale(1.1);
      }

      svg {
        fill: $silver;
        height: 1rem;
        width: 1rem;
      }
    }


  }
}


.footer-tag {
  text-transform: uppercase;
  font-size: 1rem;
  text-align: right;

  @media (max-width:$medium-media-query-size) {
    text-align: center;
    margin-top: 1rem;
    font-size: 0.85rem;
  }

}

.footer-address {
  line-height: 1.2;
  text-align: right;
}



.footer-copy-wrapper {
  display: flex;
  justify-content: space-between;
  height: 1rem;
  align-items: center;
  margin: 0 0.5rem;
  padding: 0 2rem;

  .copyright {
    text-align: center;
    font-size: 0.5rem;
    margin: 0;
  }

  @media (max-width:$small-media-query-size) {
    padding: 0 !important;
  }

  @media (max-width:$medium-media-query-size) {
      flex-direction: column;
      padding: 20px;
      height: auto;

      .copyright {
        margin-top: 20px;
      }
  }

}