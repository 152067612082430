.title-block {
    padding: 6rem;
    text-align: center;
    background-position: center;
    background-size: cover;
    position: relative;
    z-index: 10;
    @media (min-width:$medium-media-query-size) {
        background-attachment: fixed;

    }
    @media (max-width:$medium-media-query-size) {
        padding: 3rem;

    }

    @media (max-width:$small-media-query-size) {
        padding: 1.5rem;
    }


    &:after {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: $silver;
        opacity: 0.85;
        content: '';
        z-index: -1;
    }

    h1 {
        margin: 0;
        color: #fff;
        z-index: 10;
        position: relative;
    }
    h4 {
        color: #fff;
        z-index: 10;
        position: relative;

    }
}